.stay-tune {
    background-color: black;
    width: 100%;
    height:100vh;
}

.font-large {
    font-weight: 50;
    font-size: 5em;
}

.stay-tune-text{
    margin: 0;
    background-color: transparent   ;
    color: white;   
}

.top-href{
    color: inherit;
    text-decoration:none;
    font-size: 2em;
}
.page-link-a{
    font-size: 2em;
    font-weight: 200;
    text-decoration:none;
    color: inherit;
    opacity:inherit;
    background-color: black;
    padding: 0em 0.3em;
}

.page-link-a:hover{
    color:inherit;
    font-weight: 250;
  }

.page-link-li {
    padding-bottom:1em;
}

.page-link-nav{
    margin-top: 30%;
}

.header{
    position: relative;
}

.page-link {
    list-style: none;
}
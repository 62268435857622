.about-container {
    display: flex;
    flex-direction: column;
}

.main-content {
    margin-top: 70px; /* Adjust this value based on the height of your Header */
}

.about-text {
    color: black;
}
